import React from "react";
import { Input, Row, Switch, Button, Form, Modal, Upload, Select, DatePicker } from "antd";
import { DeleteOutlined, SearchOutlined, CloudUploadOutlined } from "@ant-design/icons";

import styled from "styled-components";
import { LoaderContext, ViewContext } from "../../../providers";
import { publicService } from "../../../_service";

const defaultCommissionLevel = {
  min: null,
  max: null,
  percentage: null,
  constant: null,
  maxCommission: null,
};

export const NewProviderCommission = ({ updateCommissionList, selectedCommission }) => {
  // const { theme } = useTheme();

  const { setIsLoading, notif } = React.useContext(LoaderContext);
  const [levels, setLevels] = React.useState([defaultCommissionLevel]);
  const [percent, setPercent] = React.useState();

  React.useEffect(() => {
    if (selectedCommission) {
      setPercent(selectedCommission.percent);
    }
    return () => {};
  }, [selectedCommission]);

  const upsertCommission = async () => {
    try {
      // console.log(">>>>>>>>>>>", levels);

      const data = {
        percent: Number(percent),
      };
      // console.log(">>>>>>>>>>>", data);
      setIsLoading(true);
      let result = await publicService.api(
        selectedCommission ? "PUT" : "POST",
        selectedCommission
          ? `/commission/provider/${selectedCommission.id}`
          : "/commission/provider",
        {},
        data
      );
      setIsLoading(false);

      updateCommissionList(result);
    } catch (error) {
      setIsLoading(false);

      notif("error", error?.message || error);
    }
  };

  const updateInfoAtIndex = (info, index) => {
    setLevels((prev) =>
      prev.map((_, idx) => {
        if (idx === index) {
          return { ..._, ...info };
        }
        return _;
      })
    );
  };
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginRight: 20,
          }}
        >
          <label>percent</label>
          <Input
            value={percent}
            onChange={(e) => setPercent(e.target.value)}
            style={{ margin: 5, width: 150 }}
            size="medium"
          />
        </span>
        <Button
          disabled={!percent || !levels?.length}
          onClick={() => upsertCommission()}
          type="primary"
        >
          {selectedCommission ? "update" : "add"}
        </Button>
      </div>
    </Container>
  );
};

const Container = styled.div`
  /* margin-top: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* height: 30%; */

  & > div {
    margin: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  /* & > span {
		margin-bottom: 10px;
		max-width: 250px;
		width: 250px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		& input.ant-input {
			max-width: 250px;
		}
		& textarea.ant-input {
			max-width: 250px;
		}
		& span.ant-select-selection-item {
			max-width: 250px;
			width: 250px;
		}
	} */
  /* & button {
		max-width: 250px;
		width: 250px;
	} */

  /* & span.switchs_update {
		display: flex;
		flex-direction: row;
		& .ant-switch {
			margin: 0px 20px 10px 20px;
		}
	} */
`;
