import React from "react";
import styled from "styled-components";

import { publicService } from "../../../_service";
import { LoaderContext } from "../../../providers";

import { Card, Table, Button, Input, Drawer, Dropdown, Switch } from "antd";
import { NewProviderCommission } from "./new.provider.commission";

export const ProviderCommission = () => {
  const { setIsLoading, notif } = React.useContext(LoaderContext);
  const [list, setList] = React.useState([]);

  const [selectedCommission, setSelectedCommission] = React.useState();
  const [commissionDrawer, setCommissionDrawer] = React.useState(false);

  const getCommissions = async () => {
    try {
      setIsLoading(true);

      const commissions = await publicService.api("GET", "/commission/provider");
      setList(commissions);
      setIsLoading(false);
    } catch (error) {
      notif("error", error?.message || error);

      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getCommissions();
    return () => {};
  }, []);

  const openCommissionDrawer = (commission) => {
    setSelectedCommission(commission);
    setCommissionDrawer(true);
  };

  const columns = [
    {
      title: "id",
      render: (record, index, idx) => <>{record.id} </>,
      width: 20,
      fixed: "left",
    },

    {
      title: "percentage",
      render: (record) => <>{record?.percent} </>,
      width: 70,
    },

    {
      title: "update",
      render: (record) => (
        <Button onClick={() => openCommissionDrawer(record)} type="primary">
          update
        </Button>
      ),
      width: 80,
    },
  ];

  const updateCommissionList = (NewProviderCommission) => {
    if (list.find((_) => _?.id === NewProviderCommission?.id)) {
      const newList = list.map((_) => {
        if (_.id === NewProviderCommission?.id) {
          return { ..._, ...NewProviderCommission };
        }
        return _;
      });

      setList(newList);

      // setFilteredList(newFilteredList);
    } else {
      setList([NewProviderCommission, ...list]);
      // setFilteredList([...list, NewProviderCommission]);
    }

    setCommissionDrawer(false);
  };

  return (
    <Container>
      <Drawer
        destroyOnClose
        maskStyle={{
          backdropFilter: "blur(3px)",
          WebkitBackdropFilter: "blur(3px)",
          WebkitBackdropFilter: "blur(3px)",
        }}
        title={`${selectedCommission ? "update " : "add new "}commission `}
        placement={"right"}
        closable={true}
        onClose={() => setCommissionDrawer(false)}
        visible={commissionDrawer}
        key="new_commission"
        width={"400px"}
      >
        <NewProviderCommission
          updateCommissionList={updateCommissionList}
          selectedCommission={selectedCommission}
        />
      </Drawer>

      <div className="action_box">
        <p>provider commission list - count : {list?.length}</p>
        <Button onClick={() => openCommissionDrawer()} type="primary">
          add new provider commission +
        </Button>
      </div>

      <div>
        <Card size="small" bordered={false} style={{ width: "100%" }}>
          <Table
            // scroll={{
            //   x: 900,
            //   y: window.innerHeight - 300,
            // }}
            bordered
            rowKey={(record) => record.id}
            dataSource={list}
            columns={columns}
            pagination={null}
          />
        </Card>
      </div>
    </Container>
  );
};

const Container = styled.div`
  & .action_box {
    background-color: white;
    margin: 0px;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & p {
      margin: 0;
    }
  }

  & .networks {
    background-color: white;
    margin: 0px;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;
    & p {
      margin: 0;
    }
    & span:first-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    & span:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      & button {
        margin-right: 10px;
      }
    }
  }
`;

const SSwitch = styled(Switch)`
  display: block;
  margin: auto;
`;
