import styled from "styled-components";
export * from "./Dashboard";
export * from "./asset";
export * from "./commission";
export * from "./provider.commission";

export * from "./gateways";
export * from "./affiliation";
export * from "./email";

export const Container = styled.div`
  /* overflow: auto;
	height: 100%; */
  margin: 12px;
  background: ${({ theme }) => theme.mainColor};
  border-radius: 5px;
  padding: 10px;
  min-height: calc(100vh - 110px);
  @media (max-width: 768px) {
    padding: 5px;
    margin: 3px;
  }
`;
